import React, { useRef } from 'react';
import { IPayPalButtonProps } from '../PayPalButton.types';
import { TestIds } from '../constants';
import { toQueryString } from '../../../core/commons/urlUtils';
import { activateBySpaceOrEnterButton } from '../../../core/commons/a11y';
import style from './style/PayPalButton.scss';

const PayPalButton: React.FC<IPayPalButtonProps> = props => {
  const {
    id,
    formTarget,
    cmdType,
    itemName,
    itemNumber,
    buildNotation,
    business,
    currencyCode,
    amount,
    submitImageSrc,
    submitImageAriaLabel,
    trackingPixelImgSrc,
    currentUrl,
    onMouseEnter,
    onMouseLeave,
    metaSiteId,
    userId,
    visitorId,
  } = props;

  const form = useRef<HTMLFormElement>(null);
  const notifyUrl = `https://www.wix.com/_serverless/paypal-callback?${toQueryString(
    { metaSiteId, userId, visitorId, renderer: 'thunderbolt' },
  )}`;

  return (
    <div
      id={id}
      style={{ height: 'auto' }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <form
        target={formTarget}
        action="https://www.paypal.com/cgi-bin/webscr"
        method="post"
        ref={form}
      >
        <input type="hidden" name="cmd" value={cmdType} />
        <input type="hidden" name="item_name" value={itemName || undefined} />
        <input
          type="hidden"
          name="item_number"
          value={itemNumber || undefined}
        />
        <input type="hidden" name="bn" value={buildNotation} />
        <input type="hidden" name="business" value={business} />
        <input type="hidden" name="currency_code" value={currencyCode} />
        <input type="hidden" name="notify_url" value={notifyUrl} />
        <input type="hidden" name="return" value={currentUrl.href} />
        <input type="hidden" name="cancel_return" value={currentUrl.href} />
        <input type="hidden" name="amount" value={amount || undefined} />

        <img
          alt=" "
          className={style.submitImage}
          role="button"
          src={submitImageSrc}
          tabIndex={0}
          aria-label={submitImageAriaLabel}
          data-testid={TestIds.submitImg}
          onClick={e => {
            e.preventDefault();
            form.current!.submit();
          }}
          onKeyDown={activateBySpaceOrEnterButton}
        />
        <img
          alt=" "
          className={style.trackingPixel}
          width="1"
          height="1"
          role="none"
          src={trackingPixelImgSrc}
          data-testid={TestIds.trackingPixel}
        />
      </form>
    </div>
  );
};

export default PayPalButton;
